.navLink.active {
    border-top: none !important;
    border-color: transparent !important;
    border-bottom-color: #2C2351 !important;
}

.navLink {
    color: #2C2351 !important;
    border-top: solid transparent !important;
    /* 設置 Nav.Link 的字體顏色 */
}