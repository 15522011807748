.footerContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: #ffffff;
    /* 可以根據你的設計調整背景顏色 */
    /* z-index: 1000; */
    /* 確保 Nav 在最上層 */
    /* padding-bottom: .6rem; */
}

.navLink {
    color: #2C2351 !important;
    border-top: solid rgba(00, 00, 00, 0.1) !important;
    border-radius: 0% !important;
    /* 設置 Nav.Link 的字體顏色 */
}

.activeNavLink {
    border-top-left-radius: 0% !important;
    border-top-right-radius: 0% !important;
    background-color: transparent !important;
    border-top-color: #2C2351 !important;
}