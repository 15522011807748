.avatar-container {
    margin-top: 20px;
    /* 距离上方的距离 */
}

.avatar-wrapper {
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    /* 添加阴影效果 */
}

.avatar-image {
    width: 90px;
    /* 适当调整图片大小 */
    height: 90px;
    /* 适当调整图片大小 */
    object-fit: cover;
}